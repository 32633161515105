@import '../../assets/scss/common/variables';

.membershipContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem 6rem;
  background: linear-gradient(180deg, rgba(33, 150, 243, 0.05) 0%, rgba(33, 150, 243, 0) 100%);

  .heroSection {
    text-align: center;
    margin: 4rem auto 6rem;
    max-width: 800px;

    h1 {
      font-size: 3.5rem;
      font-weight: 800;
      background: linear-gradient(135deg, #2196f3, #1565c0);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 1.5rem;
      letter-spacing: -0.02em;
    }

    p {
      font-size: 1.4rem;
      color: var(--text-secondary);
      margin-bottom: 2rem;
    }

    .gradientLine {
      height: 4px;
      width: 80px;
      margin: 0 auto;
      background: linear-gradient(90deg, #2196f3, #1565c0);
      border-radius: 2px;
    }
  }

  .plansContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 2rem;
    margin-bottom: 6rem;
    perspective: 1000px;
  }

  .planCard {
    position: relative;
    background: white;
    border-radius: 1.5rem;
    padding: 2.5rem;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 6px;
      background: var(--plan-color);
      opacity: 0.8;
    }

    &.popular {
      transform: scale(1.05);
      border: 2px solid var(--plan-color);
      box-shadow: 0 20px 60px rgba(21, 101, 192, 0.15);
      
      &::before {
        height: 8px;
      }
    }

    .popularBadge {
      position: absolute;
      top: 1.5rem;
      right: -2rem;
      background: var(--plan-color);
      color: white;
      padding: 0.5rem 2.5rem;
      font-size: 0.9rem;
      font-weight: 600;
      transform: rotate(45deg);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    }

    .planIcon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;
      color: white;
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    }

    h2 {
      font-size: 1.8rem;
      font-weight: 700;
      color: var(--text-primary);
      margin-bottom: 1rem;
    }

    .planDescription {
      color: var(--text-secondary);
      font-size: 1.1rem;
      margin-bottom: 2rem;
      line-height: 1.5;
    }

    .pricing {
      margin-bottom: 2rem;
      display: flex;
      align-items: baseline;

      .price {
        font-size: 2.5rem;
        font-weight: 800;
        color: var(--plan-color);
      }

      .period {
        color: var(--text-secondary);
        margin-left: 0.5rem;
        font-size: 1.1rem;
      }
    }

    .features {
      list-style: none;
      padding: 0;
      margin-bottom: 2rem;

      li {
        padding: 0.8rem 0;
        color: var(--text-secondary);
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        
        svg {
          margin-right: 1rem;
          flex-shrink: 0;
        }
      }
    }

    .subscribeButton {
      width: 100%;
      padding: 1.2rem;
      border: none;
      border-radius: 1rem;
      color: white;
      font-weight: 600;
      font-size: 1.1rem;
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
      }

      &:active {
        transform: translateY(1px);
      }
    }
  }

  .benefits {
    margin-bottom: 6rem;

    h2 {
      text-align: center;
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 3rem;
      color: var(--text-primary);
      background: linear-gradient(135deg, #2196f3, #1565c0);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .benefitsGrid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 2rem;
    }

    .benefitCard {
      background: white;
      padding: 2.5rem;
      border-radius: 1.5rem;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
      text-align: center;
      transition: all 0.3s ease;
      border: 1px solid rgba(0, 0, 0, 0.05);

      .benefitIcon {
        font-size: 3rem;
        margin-bottom: 1.5rem;
        display: inline-block;
      }

      h3 {
        font-size: 1.4rem;
        color: var(--text-primary);
        margin-bottom: 1rem;
        font-weight: 600;
      }

      p {
        color: var(--text-secondary);
        line-height: 1.6;
        font-size: 1.1rem;
      }
    }
  }

  .faq {
    max-width: 800px;
    margin: 0 auto;

    h2 {
      text-align: center;
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 3rem;
      background: linear-gradient(135deg, #2196f3, #1565c0);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .faqList {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .faqItem {
      background: white;
      border-radius: 1rem;
      overflow: hidden;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
      border: 1px solid rgba(0, 0, 0, 0.05);
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: translateX(5px);
      }

      &.active {
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
      }

      .faqQuestion {
        padding: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          font-size: 1.2rem;
          color: var(--text-primary);
          font-weight: 600;
          margin: 0;
        }

        span {
          color: #2196f3;
          font-size: 0.8rem;
        }
      }

      .faqAnswer {
        padding: 0 1.5rem 1.5rem;
        color: var(--text-secondary);
        line-height: 1.6;
        font-size: 1.1rem;
      }
    }
  }
}

// Responsive design
@media (max-width: 768px) {
  .membershipContainer {
    padding: 1rem 1rem 4rem;

    .heroSection {
      margin: 2rem auto 4rem;

      h1 {
        font-size: 2.5rem;
      }

      p {
        font-size: 1.2rem;
      }
    }

    .plansContainer {
      grid-template-columns: 1fr;
      gap: 1.5rem;
      margin-bottom: 4rem;

      .planCard {
        &.popular {
          transform: scale(1);
        }
      }
    }

    .benefitsGrid {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }

    .faq {
      h2 {
        font-size: 2rem;
      }
    }
  }
} 