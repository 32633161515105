@import '../../assets/scss/common/variables';

.legalContainer {
  padding: $spacing-xl 0;
  min-height: 100vh;
  background: linear-gradient(to bottom, rgba($primary-color, 0.02), rgba($secondary-color, 0.02));
}

.legalPaper {
  padding: $spacing-xl;
  background: white;
  border-radius: $border-radius-xl;
  box-shadow: $box-shadow-sm;
  border: 1px solid rgba($primary-color, 0.1);
  margin-bottom: $spacing-xl;

  .title {
    color: $text-color;
    font-weight: 700;
    margin-bottom: $spacing-md;
    background: linear-gradient(135deg, $primary-color, $secondary-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .section {
    margin-bottom: $spacing-xl;
    padding-bottom: $spacing-lg;
    border-bottom: 1px solid rgba($primary-color, 0.1);

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    h6 {
      color: $text-color;
      font-weight: 600;
      margin-bottom: $spacing-md;
    }

    p {
      color: $text-color-light;
      line-height: 1.8;
      font-size: 1rem;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .legalContainer {
    padding: $spacing-md;
  }

  .legalPaper {
    padding: $spacing-lg;

    .title {
      font-size: 1.8rem;
    }

    .section {
      margin-bottom: $spacing-lg;
      padding-bottom: $spacing-md;

      h6 {
        font-size: 1.2rem;
      }

      p {
        font-size: 0.95rem;
      }
    }
  }
} 