@import '../../assets/scss/common/variables';

.container {
  padding: $spacing-xl;
  min-height: 100vh;
  background: linear-gradient(to bottom, rgba($primary-color, 0.02), rgba($secondary-color, 0.02));
}

.hero {
  text-align: center;
  margin-bottom: $spacing-xl * 1.5;
  padding: $spacing-xl $spacing-md;

  .title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: $spacing-md;
    background: linear-gradient(135deg, $primary-color, $secondary-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 0.8s ease-out;
  }

  .subtitle {
    color: $text-color-light;
    font-size: $font-size-lg;
    animation: fadeIn 0.8s ease-out 0.2s both;
  }
}

.searchSection {
  margin-bottom: $spacing-xl * 1.5;
  background: white;
  border-radius: $border-radius-xl;
  padding: $spacing-lg;
  box-shadow: $box-shadow-sm;
  transition: all $transition-normal;

  &:hover {
    box-shadow: $box-shadow-md;
  }

  .searchBar {
    display: flex;
    align-items: center;
    padding: $spacing-md;
    border-radius: $border-radius-lg;
    background: rgba($primary-color, 0.02);
    transition: all $transition-normal;

    &:hover {
      background: rgba($primary-color, 0.04);
    }

    .searchIcon {
      color: $text-color-light;
      margin-right: $spacing-md;
    }

    .searchControls {
      display: flex;
      gap: $spacing-sm;
      margin-left: auto;
    }
  }

  .filterSection {
    padding: $spacing-md 0;

    .filterGroup {
      margin-bottom: $spacing-sm;

      .groupButton {
        color: $text-color;
        font-weight: 500;
        text-transform: none;
        padding: $spacing-sm;
        border-radius: $border-radius-md;
        background: rgba($primary-color, 0.02);
        transition: all $transition-fast;

        &:hover {
          background: rgba($primary-color, 0.04);
          transform: translateY(-1px);
        }
      }
    }
  }

  .filterChip {
    transition: all $transition-fast;
    font-size: $font-size-sm;
    background: rgba($primary-color, 0.05);
    border-color: rgba($primary-color, 0.2);
    
    &:hover {
      transform: translateY(-1px);
      box-shadow: $box-shadow-sm;
      background: rgba($primary-color, 0.1);
      border-color: rgba($primary-color, 0.3);
    }
  }
}

.companiesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: $spacing-xl;
  margin-bottom: $spacing-xl * 1.5;
}

.companyCard {
  background: white;
  border-radius: $border-radius-xl;
  overflow: hidden;
  transition: all $transition-normal;
  border: 1px solid rgba($primary-color, 0.1);

  &:hover {
    transform: translateY(-5px);
    box-shadow: $box-shadow-lg;
    border-color: rgba($primary-color, 0.2);
  }

  .cardHeader {
    position: relative;
    padding: $spacing-xl;
    background: linear-gradient(135deg, rgba($primary-color, 0.05) 0%, rgba($secondary-color, 0.05) 100%);
    display: flex;
    justify-content: center;
    align-items: center;

    .companyLogo {
      width: 100px;
      height: 100px;
      object-fit: contain;
      transition: all $transition-normal;

      &:hover {
        transform: scale(1.05);
      }
    }

    .saveButton {
      position: absolute;
      top: $spacing-md;
      right: $spacing-md;
      background: rgba(white, 0.9);
      backdrop-filter: blur(4px);
      transition: all $transition-fast;

      &:hover {
        background: white;
        transform: scale(1.1);
      }
    }
  }

  .cultureScore {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-sm;

    .cultureLabel {
      display: flex;
      align-items: center;
      gap: $spacing-sm;
      width: 150px;
      color: $text-color-light;
    }

    .progressBar {
      flex-grow: 1;
      height: 6px;
      border-radius: $border-radius-sm;
      background: linear-gradient(90deg, $primary-color, $secondary-color);
      opacity: 0.8;
    }

    .scoreValue {
      width: 40px;
      text-align: right;
      color: $text-color;
      font-weight: 500;
    }
  }

  .benefitChip {
    background: rgba($primary-color, 0.05);
    border-color: rgba($primary-color, 0.2);
    color: $text-color;
    transition: all $transition-fast;

    &:hover {
      background: rgba($primary-color, 0.1);
      border-color: rgba($primary-color, 0.3);
      transform: translateY(-1px);
    }
  }

  .applyButton {
    margin-top: $spacing-lg;
    background: linear-gradient(135deg, $primary-color, $secondary-color);
    transition: all $transition-normal;

    &:hover {
      transform: translateY(-2px);
      box-shadow: $box-shadow-md;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: $breakpoint-mobile) {
  .container {
    padding: $spacing-md;
  }

  .hero {
    padding: $spacing-lg $spacing-md;

    .title {
      font-size: 2rem;
    }
  }

  .companiesGrid {
    grid-template-columns: 1fr;
    gap: $spacing-lg;
  }

  .companyCard {
    .cardHeader {
      padding: $spacing-lg;

      .companyLogo {
        width: 80px;
        height: 80px;
      }
    }

    .cardContent {
      padding: $spacing-md;
    }
  }
} 